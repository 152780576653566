<template>
  <div class="bg">
    <canvas id="canvas"></canvas>
    <div class="bigbig">
      <div class="bigbig-header">
        <span class="logo-span"
          ><img
            data-v-da4ae17e=""
            src="https://static.dianmi-north.cn/favicon.ico"
            class="logo"
          />
          点米科技
        </span>
        <span class="bigbig-center"> 点米灵活用工数据可视化</span>
        <span class="bigbig-right">当前时间：2021-06-22 16:00:00</span>
      </div>

      <div class="bigbig-content-left">
        <div class="box-fg-render" style="max-height: 200px">
          <div class="box-render"><span class="fg-font"></span> 基本信息</div>

          <div class="box-render">
            <a-row class="base-newinfo">
              <a-col :span="8">
                <div class="card-title">今日新增人员</div>
                <div class="card-value">
                  <span class="card-impfont">597</span>人
                </div>
              </a-col>
              <a-col :span="8">
                <div class="card-title">今日发放金额</div>
                <div class="card-value">
                  <span class="card-impfont">597</span>万
                </div>
              </a-col>
              <a-col :span="8">
                <div class="card-title">今日开票金额</div>
                <div class="card-value">
                  <span class="card-impfont">597</span>万
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="box-fg-render" style="height: 100%">
          <div class="box-render"><span class="fg-font"></span> 发放排行</div>

          <div class="box-render" style="height: 80%">
            <div id="container"></div>
          </div>
        </div>
      </div>

      <div class="bigbig-content-center">
        <div class="box-fg-render" style="height: 100%">
          <div class="box-render"><span class="fg-font"></span> 开票数据</div>
          <div class="box-render" style="height: 70%">
            <div id="openTicketcontainer"></div>
          </div>

          <div class="box-render" style="height: 26%; width: 66%; float: left">
            <div id="tickbtcontainer" style="height: 100%"></div>
          </div>
          <div
            class="box-render"
            style="height: 26%; width: 33%; float: right; padding: 40px"
          >
            <div class="big-card-title">总开票金额</div>
            <div class="big-card-value">
              <span class="big-card-money">12,381,27</span>万
            </div>
          </div>
        </div>
      </div>
      <div class="bigbig-content-right">
        <div class="box-fg-render" style="height: 100%">
          <div class="box-render">
            <span class="fg-font"></span> 灵活用工岗位
          </div>

          <div class="box-render" style="height: 50%">
            <div id="chiyunContainer" style="height: 100%"></div>
          </div>

          <div class="box-render">
            <span class="fg-font"></span> 灵活用工增长
          </div>

          <div class="box-render" style="height: 40%">
            <div id="zxContainer" style="height: 100%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { Chart, registerShape, Util } from "@antv/g2";
import { Scene } from "@antv/l7";
import { CountryLayer } from "@antv/l7-district";
import { Mapbox } from "@antv/l7-maps";
import DataSet from "@antv/data-set";
const otherRatio = 6.67 / 100; // Other 的占比
const otherOffsetAngle = otherRatio * Math.PI; // other 占的角度的一半

function getTextAttrs(cfg) {
  return {
    ...cfg.style,
    fontSize: cfg.data.size,
    text: cfg.data.text,
    textAlign: "center",
    fontFamily: cfg.data.font,
    fill: cfg.color,
    textBaseline: "Alphabetic",
  };
}

export default {
  mixins: [AppComponentBase],
  data() {
    return {
      sendMoneyData: [
        { type: "美团项目-自然人1", value: 34 },
        { type: "特别奇怪的项目-自然人", value: 85 },
        { type: "美团项目-自然人2", value: 103 },
        { type: "美团项目-自然人3", value: 142 },
        { type: "美团项目-自然人4", value: 251 },
        { type: "美团项目-自然人5", value: 367 },
        { type: "美团项目-自然人6", value: 491 },
        { type: "美团项目-自然人7", value: 672 },
        { type: "美团项目-自然人8", value: 868 },
        { type: "美团项目-自然人9", value: 1234 },
        { type: "美团项目-自然人10", value: 672 },
        { type: "美团项目-自然人11", value: 868 },
        { type: "美团项目-自然人12", value: 1234 },
      ],
      openTicketData: [
        { type: "点米立德（深圳）科技有限公司", value: 32 },
        { type: "点米服务外包（上海）有限公司", value: 24 },
        { type: "点米（北京）科技有限公司", value: 22 },
        { type: "宁波裕米企业服务有限公司", value: 17 },
        { type: "其他", value: 5 },
      ],
      openPingTicketData: [
        { type: "专票", value: 78.4 },
        { type: "普票", value: 21.6 },
      ],
      otherTicketData: [
        { type: "慈溪裕米诺德企业服务有限公司", value: 1.3 },
        { type: "海宁惠曼企业管理服务有限公司", value: 1.1 },
        { type: "马鞍山诺米企业管理服务有限公司", value: 0.9 },
        { type: "海南裕米诺德企业服务有限公司", value: 0.8 },
        { type: "安徽常谦企业管理有限公司", value: 0.5 },
        { type: "其他", value: 0.4 },
      ],
      openTicketTopFiveData: [
        { type: "点米立德（深圳）科技有限公司", value: 27 },
        { type: "点米服务外包（上海）有限公司", value: 25 },
        { type: "点米（北京）科技有限公司", value: 18 },
        { type: "宁波裕米企业服务有限公司", value: 15 },
        { type: "宁波裕米企业服务有限公司北京分公司", value: 10 },
        { type: "其他", value: 5 },
      ],
      cloudYunData: [
        { value: 9, name: "保洁服务" },

        { value: 17, name: "外卖员" },
        { value: 8, name: "保安" },
        { value: 21, name: "网约车司机" },
        { value: 2, name: "会计" },
        { value: 11, name: "设计师" },

        { value: 15, name: "软件" },
        { value: 12, name: "网管" },
        { value: 7, name: "闪送" },
        { value: 4, name: "配送" },
        { value: 6, name: "协管员" },
        { value: 6, name: "Graphics" },
        { value: 6, name: "Graph" },
        { value: 6, name: "Hierarchy" },
        { value: 6, name: "Labeling" },
        { value: 6, name: "Layout" },
        { value: 6, name: "Quantitative" },
        { value: 6, name: "Relation" },
        { value: 6, name: "Statistics" },
        { value: 6, name: "可视化" },
        { value: 6, name: "数据" },
        { value: 6, name: "数据可视化" },
      ],
      zxianData: [
        { year: "2015", value: 3 },
        { year: "2016", value: 4 },
        { year: "2017", value: 3.5 },
        { year: "2018", value: 5 },
        { year: "2019", value: 4.9 },
        { year: "2020", value: 6 },
        { year: "2021", value: 7 },
      ],
    };
  },
  mounted() {
    require("./bg.js");
    // 给 point 注册一个词云的 shape
    registerShape("point", "cloud", {
      draw(cfg, container) {
        const attrs = getTextAttrs(cfg);
        const textShape = container.addShape("text", {
          attrs: {
            ...attrs,
            x: cfg.x,
            y: cfg.y,
          },
        });
        if (cfg.data.rotate) {
          Util.rotate(textShape, (cfg.data.rotate * Math.PI) / 180);
        }
        return textShape;
      },
    });
    this.initSendMoneyData();
    this.initMapData();
    this.initOpenTicketPointData();
    this.initCloudImage();
    this.initZxianImage();

    // this.initOpenTicketTopFive();
  },

  methods: {
    initSendMoneyData() {
      const chart = new Chart({
        container: "container",
        autoFit: true,
        height: "100%",
      });
      chart.data(this.sendMoneyData);
      chart.scale({
        value: {
          max: 1400,
          min: 0,
          alias: "金额（万）",
        },
      });
      chart.axis("type", {
        title: null,
        tickLine: null,
        line: null,
      });

      chart.axis("value", {
        label: null,
        title: {
          offset: 30,
          style: {
            fontSize: 12,
            fontWeight: 300,
          },
        },
      });
      chart.legend(false);
      chart.coordinate().transpose();
      chart
        .interval()
        .position("type*value")
        .size(26)
        .label("value", {
          style: {
            fill: "#8d8d8d",
          },
          offset: 10,
        });
      chart.interaction("element-active");
      chart.render();
    },
    initopenTicketData() {
      const chart = new Chart({
        container: "openTicketcontainer",
        autoFit: true,
        height: 450,
        width: "100%",
      });
      chart.legend(true);
      chart.tooltip({
        showMarkers: true,
      });

      const view1 = chart.createView({
        region: {
          start: {
            x: 0,
            y: 0,
          },
          end: {
            x: 0.5,
            y: 1,
          },
        },
      });
      view1.coordinate("theta", {
        radius: 0.7,
        startAngle: 0 + otherOffsetAngle,
        endAngle: Math.PI * 2 + otherOffsetAngle,
      });
      view1.data(this.openTicketData);
      view1.interaction("element-highlight");
      view1
        .interval()
        .adjust("stack")
        .position("value")
        .color("type", ["#38c060", "#2593fc"])
        .label("value", function () {
          return {
            offset: 10,
            content: (obj) => {
              return obj.type + "\n" + obj.value + "%";
            },
          };
        });

      const view2 = chart.createView({
        region: {
          start: {
            x: 0.5,
            y: 0.1,
          },
          end: {
            x: 1,
            y: 0.9,
          },
        },
      });
      view2.axis(false);
      view2.data(this.otherTicketData);
      view2.interaction("element-highlight");
      view2
        .interval()
        .adjust("stack")
        .position("value")
        .color("type", [
          "#063d8a",
          "#0b53b0",
          "#1770d6",
          "#2593fc",
          "#47abfc",
          "#6dc1fc",
          "#94d6fd",
          "#bbe7fe",
        ])
        .label("value", {
          position: "right",
          offsetX: 5,
          offsetY: 10,
          content: (obj) => {
            return obj.type + " " + obj.value + "%";
          },
        });
      chart.render();
      this.drawLinkArea();
      chart.on("afterpaint", function () {
        this.drawLinkArea(chart);
      });
    },
    initMapData() {
      const ProvinceData = [
        {
          name: "云南省",
          code: 530000,
          value: 17881.12,
        },
        {
          name: "黑龙江省",
          code: 230000,
          value: 16361.62,
        },
        {
          name: "贵州省",
          code: 520000,
          value: 14806.45,
        },
        {
          name: "北京市",
          code: 110000,
          value: 30319.98,
        },
        {
          name: "河北省",
          code: 130000,
          value: 36010.27,
        },
        {
          name: "山西省",
          code: 140000,
          value: 16818.11,
        },
        {
          name: "吉林省",
          code: 220000,
          value: 15074,
        },
        {
          name: "宁夏回族自治区",
          code: 640000,
          value: 3705.18,
        },
        {
          name: "辽宁省",
          code: 210000,
          value: 25315.35,
        },
        {
          name: "海南省",
          code: 460000,
          value: 4832.05,
        },
        {
          name: "内蒙古自治区",
          code: 150000,
          value: 17289.22,
        },
        {
          name: "天津市",
          code: 120000,
          value: 18809.64,
        },
        {
          name: "新疆维吾尔自治区",
          code: 650000,
          value: 12199.08,
        },
        {
          name: "上海市",
          code: 310000,
          value: 32679.87,
        },
        {
          name: "陕西省",
          code: 610000,
          value: 24438.32,
        },
        {
          name: "甘肃省",
          code: 620000,
          value: 8246.07,
        },
        {
          name: "安徽省",
          code: 340000,
          value: 30006.82,
        },
        {
          name: "香港特别行政区",
          code: 810000,
          value: 0,
        },
        {
          name: "广东省",
          code: 440000,
          value: 97277.77,
        },
        {
          name: "河南省",
          code: 410000,
          value: 48055.86,
        },
        {
          name: "湖南省",
          code: 430000,
          value: 36425.78,
        },
        {
          name: "江西省",
          code: 360000,
          value: 21984.78,
        },
        {
          name: "四川省",
          code: 510000,
          value: 40678.13,
        },
        {
          name: "广西壮族自治区",
          code: 450000,
          value: 20353.51,
        },
        {
          name: "江苏省",
          code: 320000,
          value: 92595.4,
        },
        {
          name: "澳门特别行政区",
          code: 820000,
          value: null,
        },
        {
          name: "浙江省",
          code: 330000,
          value: 56197.15,
        },
        {
          name: "山东省",
          code: 370000,
          value: 76469.67,
        },
        {
          name: "青海省",
          code: 630000,
          value: 2865.23,
        },
        {
          name: "重庆市",
          code: 500000,
          value: 20363.19,
        },
        {
          name: "福建省",
          code: 350000,
          value: 35804.04,
        },
        {
          name: "湖北省",
          code: 420000,
          value: 39366.55,
        },
        {
          name: "西藏自治区",
          code: 540000,
          value: 1477.63,
        },
        {
          name: "台湾省",
          code: 710000,
          value: null,
        },
      ];
      const scene = new Scene({
        id: "openTicketcontainer",
        map: new Mapbox({
          center: [116.2825, 39.9],
          pitch: 0,
          style: "dark",
          zoom: 3,
          minZoom: 0,
          maxZoom: 10,
        }),
        logoVisible: false,
      });

      scene.on("loaded", () => {
        new CountryLayer(scene, {
          data: ProvinceData,
          joinBy: ["NAME_CHN", "name"],
          depth: 1,
          fill: {
            color: "#1b1e23",
          },
          bubble: {
            enable: true,
            color: "#0c2a56",
            size: {
              field: "value",
              values: [3, 20],
            },
          },
          popup: {
            enable: true,
            Html: (props) => {
              return `<span>${props.NAME_CHN}:</span><span>${props.value}</span>`;
            },
          },
        });
      });
    },
    initOpenTicketPointData() {
      const chart = new Chart({
        container: "tickbtcontainer",
        autoFit: true,
        height: "100%",
        width: "100%",
      });
      chart.data(this.openPingTicketData);
      chart.legend(false);
      chart.tooltip({
        showMarkers: false,
      });
      chart.facet("rect", {
        fields: ["type"],
        padding: 20,
        showTitle: false,
        eachView: (view, facet) => {
          const data = facet.data;
          let color;
          if (data[0].type === "专票") {
            color = "#0a9afe";
          } else {
            color = "#f0657d";
          }
          data.push({
            type: "其他",
            value: 100 - data[0].value,
            color: "#fff",
          });
          view.data(data);
          view.coordinate("theta", {
            radius: 0.8,
            innerRadius: 0.5,
          });
          view
            .interval()
            .adjust("stack")
            .position("value")
            .color("type", [color, "#fff"])
            .style({
              opacity: 1,
            });
          view.annotation().text({
            position: ["50%", "50%"],
            content: data[0].type,
            style: {
              fontSize: 12,
              fill: "#8c8c8c",
              fontWeight: 300,
              textBaseline: "bottom",
              textAlign: "center",
            },
            offsetY: -12,
          });

          view.annotation().text({
            position: ["50%", "50%"],
            content: data[0].value,
            style: {
              fontSize: 18,
              fill: "#fff",
              fontWeight: 500,
              textAlign: "center",
            },
            offsetY: 10,
          });

          view.interaction("element-active");
        },
      });
      chart.render();
    },
    initOpenTicketTopFive() {
      const chart = new Chart({
        container: "openTicketTopFivve",
        autoFit: true,
      });
      chart.legend(false);
      chart.tooltip({
        showMarkers: false,
      });
      chart.coordinate("theta", {
        startAngle: Math.PI, // 起始角度
        endAngle: Math.PI * (3 / 2), // 结束角度
      });

      chart.data(this.openTicketTopFiveData);

      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });

      chart
        .interval()
        .adjust("stack")
        .position("value")
        .color("type")
        .label("type");

      chart.interaction("element-active");

      chart.render();
    },
    initCloudImage() {
      const dv = new DataSet.View().source(this.cloudYunData);
      const range = dv.range("value");
      const min = range[0];
      const max = range[1];
      const imageMask = new Image();
      imageMask.crossOrigin = "";
      imageMask.src = "https://static.dianmi-north.cn/favicon.ico";
      imageMask.onload = () => {
        dv.transform({
          type: "tag-cloud",
          fields: ["name", "value"],
          imageMask,
          font: "Verdana",

          padding: 10,
          timeInterval: 5000, // max execute time
          rotate() {
            let random = ~~(Math.random() * 4) % 4;
            if (random === 2) {
              random = 0;
            }
            return random * 90; // 0, 90, 270
          },
          fontSize(d) {
            return ((d.value - min) / (max - min)) * (32 - 8) + 8;
          },
        });
        const chart = new Chart({
          container: "chiyunContainer",
          autoFit: true,

          padding: 0,
        });
        chart.data(dv.rows);
        chart.scale({
          x: { nice: false },
          y: { nice: false },
        });
        chart.legend(false);
        chart.axis(false);
        chart.tooltip({
          showTitle: false,
          showMarkers: false,
        });
        chart.coordinate().reflect();
        chart
          .point()
          .position("x*y")
          .color("text")
          .shape("cloud")
          .state({
            active: {
              style: {
                fillOpacity: 0.4,
              },
            },
          });
        chart.interaction("element-active");
        chart.render();
      };
    },
    initZxianImage() {
      const chart = new Chart({
        container: "zxContainer",
        autoFit: true,
      });

      chart.data(this.zxianData);
      chart.scale({
        year: {
          range: [0, 1],
        },
        value: {
          min: 0,
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true, // 展示 Tooltip 辅助线
        shared: true,
      });

      chart.line().position("year*value").label("value");
      chart.point().position("year*value");

      chart.render();
    },
    drawLinkArea(chart) {
      const canvas = chart.getCanvas();
      const container = chart.backgroundGroup;
      const view1_coord = view1.getCoordinate();
      const center = view1_coord.getCenter();
      const radius = view1_coord.getRadius();
      const interval_geom = view2.geometries[0];
      const interval_container = interval_geom.container;
      const interval_bbox = interval_container.getBBox();
      const view2_coord = view2.getCoordinate();
      // area points
      const pie_start1 = {
        x: center.x + Math.cos(Math.PI * 2 - otherOffsetAngle) * radius,
        y: center.y + Math.sin(Math.PI * 2 - otherOffsetAngle) * radius,
      };
      const pie_start2 = {
        x: center.x + Math.cos(otherOffsetAngle) * radius,
        y: center.y + Math.sin(otherOffsetAngle) * radius,
      };
      const interval_end1 = {
        x: interval_bbox.minX,
        y: view2_coord.end.y,
      };
      const interval_end2 = {
        x: interval_bbox.minX,
        y: view2_coord.start.y,
      };
      const path = [
        ["M", pie_start1.x, pie_start1.y],
        ["L", pie_start2.x, pie_start2.y],
        ["L", interval_end2.x, interval_end2.y],
        ["L", interval_end1.x, interval_end1.y],
        ["Z"],
      ];
      container.addShape("path", {
        attrs: {
          path,
          fill: "#e9f4fe",
        },
      });
      canvas.draw();
    },
  },
};
</script>

<style lang="less" scoped>
/*chrome--------------------------------------------start*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #7b85ff10;
  border-radius: 8px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #7b85ff30;
  border-radius: 8px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7b85ff40;
}
.el-menu::-webkit-scrollbar,
.el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}
.el-menu:hover::-webkit-scrollbar,
.el-table__body-wrapper:hover::-webkit-scrollbar {
  display: block;
}
/*chrome--------------------------------------------end*/
/*IE--------------------------------------------start*/
* {
  /*三角箭头的颜色*/
  scrollbar-arrow-color: #7b85ff10;
  /*滚动条滑块按钮的颜色*/
  scrollbar-face-color: #7b85ff10;
  /*滚动条整体颜色*/
  scrollbar-highlight-color: #7b85ff10;
  /*滚动条阴影*/
  scrollbar-shadow-color: #7b85ff10;
  /*滚动条轨道颜色*/
  scrollbar-track-color: #7b85ff10;
  /*滚动条3d亮色阴影边框的外观颜色——左边和上边的阴影色*/
  scrollbar-3dlight-color: #7b85ff10;
  /*滚动条3d暗色阴影边框的外观颜色——右边和下边的阴影色*/
  scrollbar-darkshadow-color: #7b85ff10;
  /*滚动条基准颜色*/
  scrollbar-base-color: #f4f4f5;
}
.el-table__body-wrapper,
.el-menu {
  /*IE下隐藏*/
  -ms-overflow-style: none;
}

.el-table__body-wrapper:hover,
.el-menu:hover {
  /*IE下显示*/
  -ms-overflow-style: auto;
}
/*IE--------------------------------------------end*/
#openTicketcontainer {
  position: absolute;
  top: 1%;
  left: 1%;
  right: 1%;
  bottom: 1%;
}
#container {
  position: absolute;
  top: 1%;
  left: 1%;
  right: 1%;
  bottom: 1%;
}
.big-card-title {
  text-align: center;
}
.big-card-value {
  text-align: center;
  margin-top: 40px;
  font-size: 30px;
  font-weight: bolder;
  color: rgb(0, 160, 223);
  letter-spacing: 3px;
}
.bg {
  background: #060e1b;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

canvas {
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.codrops-demos {
  font-size: 0.8em;
  text-align: center;
  position: absolute;
  z-index: 99;
  width: 96%;
}

.codrops-demos a {
  display: inline-block;
  margin: 0.35em 0.1em;
  padding: 0.5em 1.2em;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  border-radius: 2px;
  font-size: 110%;
  border: 2px solid transparent;
  color: #fff;
}

.codrops-demos a:hover,
.codrops-demos a.current-demo {
  border-color: #383a3c;
}
.box-render {
  background: #7b85ff10;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  position: relative;
  .fg-font {
    display: block;
    width: 4px;
    height: 20px;
    margin-right: 5px;
    background: rgb(0, 160, 223);
    float: left;
  }
}

.base-newinfo {
  text-align: center;
}
.card-title {
  margin-bottom: 10px;
}
.card-value {
  font-size: 12px;
  .card-impfont {
    color: rgb(0, 160, 223);
    font-size: 28px;
  }
}
.box-fg-render {
  margin-bottom: 15px;
}
.bigbig {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .logo-span {
    color: #fff;
    font-size: 22px;
    line-height: 44px;
    float: left;
  }
  .logo {
    height: 44px;
    vertical-align: top;
    margin-right: 0;
  }

  .bigbig-header {
    text-align: center;
    width: 98%;
    margin-left: 1%;
    height: 50px;
    border-bottom: #003546 2px solid;
    position: relative;

    .bigbig-center {
      color: #fff;
      font-size: 22px;
      line-height: 44px;
    }
    .bigbig-right {
      float: right;
      color: #fff;
      line-height: 44px;
    }
  }

  .bigbig-content-left {
    position: absolute;
    left: 1%;
    top: 60px;
    bottom: 50px;
    right: 75%;
    //border: #003546 2px solid;
    border-top: 0;
  }

  .bigbig-content-center {
    position: absolute;
    left: 26%;
    top: 60px;
    bottom: 50px;
    right: 26%;
    // border: #003546 2px solid;
    border-top: 0;
    border-left: 0;
  }

  .bigbig-content-right {
    position: absolute;
    left: 75%;
    top: 60px;
    bottom: 50px;
    right: 1%;
    border-top: 0;
    border-left: 0;
  }
}
</style>
