var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"login"}},[_c('a-card',[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"text-center yoyo__block"},[_c('span',[_vm._v(_vm._s(_vm.l("LogIn")))])])]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){return _vm.login($event)}}},[_c('a-form-item',{attrs:{"help":_vm.userNameError() || '',"validate-status":_vm.userNameError() ? 'error' : ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'userNameOrEmailAddress',
            {
              rules: [{ required: true, message: _vm.l('ThisFieldIsRequired') }],
            } ]),expression:"[\n            'userNameOrEmailAddress',\n            {\n              rules: [{ required: true, message: l('ThisFieldIsRequired') }],\n            },\n          ]"}],attrs:{"placeholder":_vm.l('UserNameOrEmail')},on:{"blur":function($event){return _vm.userNameOrEmailAddressOnBlur()}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"help":_vm.passwordError() || '',"validate-status":_vm.passwordError() ? 'error' : ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [{ required: true, message: _vm.l('ThisFieldIsRequired') }],
            } ]),expression:"[\n            'password',\n            {\n              rules: [{ required: true, message: l('ThisFieldIsRequired') }],\n            },\n          ]"}],attrs:{"placeholder":_vm.l('Password'),"type":"password"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),(_vm.useCaptcha)?_c('a-form-item',[_c('captcha',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'verificationCode',
            {
              rules: [{ required: true, message: _vm.l('ThisFieldIsRequired') }],
            } ]),expression:"[\n            'verificationCode',\n            {\n              rules: [{ required: true, message: l('ThisFieldIsRequired') }],\n            },\n          ]"}],ref:"captcha",attrs:{"placeholder":_vm.l('CAPTCHA'),"primaryKey":_vm.primaryKey,"type":_vm.captchaType}})],1):_vm._e(),_c('a-form-item',[_c('a-button',{staticClass:"yoyo__block",attrs:{"loading":_vm.isSubmitting,"disabled":_vm.hasErrors(_vm.form.getFieldsError()),"html-type":"submit","type":"primary"}},[_vm._v(" "+_vm._s(_vm.l("LogIn"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }